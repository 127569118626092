import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import { getAuthToken } from '../../../util/request';
import Button from '../../atoms/button/Button';

function LogoutForm() {
  const { setLoader, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogoutError = () => {
    setAuth({
      token: null,
      loginAt: null,
    });
    localStorage.removeItem('token');
  };
  const onLogout = async () => {
    setLoader(true);
    localStorage.removeItem('loginAt');
    try {
      const res = await getAuthToken();
      if (res?.data?.access_token) {
        const token = res.data.access_token;
        setAuth({
          token,
          loginAt: null,
        });
        localStorage.setItem('token', token);
      } else {
        handleLogoutError(new Error('Kein Accesstoken erhalten!'));
      }
    } catch (e) {
      handleLogoutError(e);
    }
    setLoader(false);
    navigate('/');
  };
  return (
    <>
      <p>Sie sind bereits eingeloggt.</p>
      <Button handleClick={onLogout} label="logout" />
      <Button handleClick={() => navigate('/')} label="zurück" />
    </>
  );
}

export default LogoutForm;
