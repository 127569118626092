import React from 'react';
import AppLogo from '../../components/atoms/applogo/AppLogo';
import Container from '../../components/atoms/container/Container';
import Footer from '../../components/atoms/footer/footer';
import CheckoutForm from '../../components/molecules/form/CheckoutForm';
import './checkout.css';

function CheckoutPage() {
  return (
    <Container>
      <AppLogo />
      <CheckoutForm />
      <Footer />
    </Container>
  );
}

export default CheckoutPage;
