import PropTypes from 'prop-types';
import React from 'react';
import stylePropType from 'react-style-proptype';
import './Button.css';

function Button(props) {
  const {
    label, handleClick, styleName, disabled, style,
  } = props;
  return (
    <button
      type="button"
      onClick={disabled ? null : handleClick}
      value={label}
      className={`button ${styleName}`}
      disabled={disabled}
      style={style}
    >
      {label}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  styleName: PropTypes.string,
  disabled: PropTypes.bool,
  style: stylePropType,
};

Button.defaultProps = {
  styleName: '',
  disabled: false,
  style: null,
};
export default Button;
