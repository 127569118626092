import React, {
  useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de } from 'date-fns/locale';
import { AuthContext } from '../../../contexts/AuthProvider';
import { getOrderPriceInfo } from '../../../services';
import { formatCurrency } from '../../../util/utils';
import Button from '../../atoms/button/Button';
import TicketDetails from '../ticket-details/TicketDetails';
import './Form.css';

registerLocale('de', de);

function SummaryForm() {
  const { state } = useLocation();

  const roundUpToNext15Min = (date) => {
    const ms = 1000 * 60 * 15; // convert minutes to ms
    return new Date(Math.ceil(date.getTime() / ms) * ms);
  };

  let bookingInfo = null;
  let ticketNumber = null;
  let checkoutDateState = roundUpToNext15Min(new Date());
  let checkoutAmountState = null;

  if (state != null) {
    bookingInfo = state.bookingInfo;
    ticketNumber = state.ticketNumber;
    checkoutDateState = state.checkoutDate || roundUpToNext15Min(new Date());
    checkoutAmountState = state.checkoutAmount;
  }

  const { setLoader } = useContext(AuthContext);
  const navigate = useNavigate();
  const [checkoutDate, setCheckoutDate] = useState(checkoutDateState);
  const [checkoutAmount, setCheckoutAmount] = useState(checkoutAmountState);

  const [datepickerOpen, setDatePickerOpen] = useState(false);

  const getParkingAmount = async () => {
    setLoader(true);
    try {
      const {
        von, produkt_id: produktId, objekttyp_id: objekttypId, standort_id: standortId,
        // eslint-disable-next-line no-unsafe-optional-chaining
      } = bookingInfo?.positionen[0]?.vermietung;
      const from = new Date(von * 1000);
      const payload = {
        rentings: [
          {
            from: from.toISOString(),
            rentingId: produktId,
            rentingLocationId: standortId,
            rentingTypeId: objekttypId,
            to: checkoutDate.toISOString(),
          },
        ],
      };
      const response = await getOrderPriceInfo(payload);
      setLoader(false);
      if (response?.totalPrice) {
        const preisLautApi = response?.totalPrice?.price || 0;
        const zahlbetragLautBuchung = bookingInfo?.zahlbetrag || 0;
        const betragVerrechnet = Math.max(preisLautApi - zahlbetragLautBuchung, 0);
        setCheckoutAmount(betragVerrechnet);
      }
    } catch (err) {
      // eslint-disable-next-line no-alert
      // window.alert(err.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getParkingAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDate, bookingInfo]);

  const onNext = () => navigate('/checkout', {
    state: {
      bookingInfo,
      ticketNumber,
      checkoutAmount,
      checkoutDate,
    },
  });

  if (state == null) {
    return (
      <div className="page-container">
        <div className="summary-form-container">
          <h1>Bitte scannen Sie erst ein Ticket.</h1>
          <Button label="zurück" handleClick={() => navigate('/')} />
        </div>
      </div>
    );
  }
  return (
    <div className="page-container">
      <div className="summary-form-container">
        <TicketDetails
          ticketNumber={ticketNumber}
          bookingInfo={bookingInfo}
        />
        <h1>Wann wollen Sie ausfahren?</h1>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div style={{ width: 'auto' }} onClick={() => setDatePickerOpen(true)}>
          <DatePicker
            locale="de"
            timeIntervals={15}
            timeCaption="Uhrzeit"
            showTimeSelect
            className="form-control"
            selected={checkoutDate}
            onChange={(val, e) => {
              setCheckoutDate(val);
              setDatePickerOpen(false);
              if (e) {
                e.stopPropagation();
              }
            }}
            onClickOutside={() => setDatePickerOpen(false)}
            open={datepickerOpen}
            minDate={roundUpToNext15Min(new Date())}
            dateFormat="dd.MM.yyyy HH:mm"
            readOnly
          />
        </div>
        <div className="total-price">
          <span>zu Zahlen:</span>
          <span>
            {` ${formatCurrency(checkoutAmount)}`}
          </span>
        </div>
        {
                    !!bookingInfo?.zahlbetrag && (
                    <div className="already-paid">
                      <span>bereits gezahlt:</span>
                      <span>
                        {` ${formatCurrency(bookingInfo.zahlbetrag)}`}
                      </span>
                    </div>
                    )
                }
        <div className="actions">
          <Button handleClick={() => navigate('/')} label="abbrechen" styleName="white-btn" />
          <Button
            handleClick={onNext}
            label="bezahlen"
            disabled={!checkoutDate || !checkoutAmount || checkoutAmount <= 0}
          />
        </div>
      </div>
    </div>
  );
}

export default SummaryForm;
