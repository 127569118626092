import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../util/utils';

function TicketDetails({ ticketNumber, bookingInfo }) {
  let positionen = null;
  if (bookingInfo?.positionen?.length) {
    positionen = bookingInfo.positionen;

    const positionDetails = positionen[positionen.length - 1];
    const { von, standort_bezeichnung: standortBezeichnung } = positionDetails.vermietung;
    const from = formatDate(new Date(von * 1000));

    return (
      <div className="ticket-details-container">
        <div>
          <span>Ticket-ID:</span>
          <span>{ticketNumber}</span>
        </div>
        <div>
          <span>Ankunft um:</span>
          <span>
            {from}
            {' '}
            Uhr
          </span>
        </div>
        <div>
          <span>Stellfläche:</span>
          <span>{standortBezeichnung}</span>
        </div>
      </div>
    );
  }
  return null;
}

TicketDetails.propTypes = {
  ticketNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bookingInfo: PropTypes.object.isRequired,
};
export default TicketDetails;
