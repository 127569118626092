import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import stylePropType from 'react-style-proptype';
import Images from '../../../themes';
import './AppLogo.css';
import Loader from '../loader/Loader';
import { AuthContext } from '../../../contexts/AuthProvider';

function AppLogo(props) {
  const { extraStyles } = props;
  const { loader } = useContext(AuthContext);
  const navigate = useNavigate();

  const onUserloginClick = () => {
    navigate('/login');
  };

  const onBrandLogoClick = () => {
    navigate('/');
  };

  return (
    <>
      <Loader showLoading={loader} />
      <div className="logo-container" style={extraStyles}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-noninteractive-element-interactions */}
        <img src={Images.logo} className="logo-img" alt="logo" onClick={onBrandLogoClick} />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-static-element-interactions */}
        <div className="user" onClick={onUserloginClick} />
      </div>
    </>
  );
}

AppLogo.propTypes = {
  extraStyles: stylePropType,
};

AppLogo.defaultProps = {
  extraStyles: null,
};

export default AppLogo;
