import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppLogo from '../../components/atoms/applogo/AppLogo';
import Button from '../../components/atoms/button/Button';
import Container from '../../components/atoms/container/Container';
import Footer from '../../components/atoms/footer/footer';
import './payment-summary.css';

function PaymentSummary({ view }) {
  const navigate = useNavigate();
  const email = localStorage.getItem('email');

  const getSummaryMessage = () => {
    switch (view) {
      case 'success':
        if (email) {
          return (
            <p>
              Ihre Zahlung wird derzeit verarbeitet.
              Sie erhalten zeitnah eine Rückmeldung per E-Mail.
            </p>
          );
        }
        return <p>Ihre Zahlung wird derzeit verarbeitet.</p>;
      case 'abort':
        return (
          <p>
            Der Zahlungsprozess war nicht erfolgreich.
            <br />
            <br />
            Bei Fragen wenden Sie sich bitte an unser Servicecenter.
          </p>
        );
      case 'failed':
        return (
          <p>
            Es ist ein Fehler mit unserem Zahlungsdienstleister aufgetreten!
            <br />
            <br />
            Bitte entschuldigen Sie diese Unannehmlichkeiten.
            <br />
            <br />
            Bei Fragen wenden Sie sich bitte an unser Servicecenter.
          </p>
        );
      case 'pending':
        if (email) {
          return (
            <p>
              Ihre Zahlung wird nun durch unseren Zahlungsdienstleister geprüft.
              <br />
              <br />
              Bei erfolgreicher Zahlung erhalten Sie ihre Bestätigung via E-Mail.
              <br />
              <br />
              Sollte ihre Zahlung nicht erfolgreich sein,
              so werden Sie darüber entsprechend benachrichtigt.
            </p>
          );
        }
        return (
          <p>
            Ihre Zahlung wird nun durch unseren Zahlungsdienstleister geprüft.
            <br />
            <br />
            Sollte ihre Zahlung nicht erfolgreich sein wenden Sie sich bitte an unser Servicecenter.
          </p>
        );
      default:
        return '';
    }
  };

  const onNext = () => {
    if (email) {
      localStorage.removeItem('email');
    }
    navigate('/');
  };

  return (
    <Container>
      <AppLogo />
      <div className="page-container">
        <div className="payment-summary">
          {getSummaryMessage()}
          <Button
            handleClick={onNext}
            label="Startseite"
          />
        </div>
      </div>
      <Footer />
    </Container>
  );
}

PaymentSummary.propTypes = {
  view: PropTypes.string,
};

PaymentSummary.defaultProps = {
  view: 'success',
};
export default PaymentSummary;
