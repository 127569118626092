import React, { useContext } from 'react';
import AppLogo from '../../components/atoms/applogo/AppLogo';
import Container from '../../components/atoms/container/Container';
import Footer from '../../components/atoms/footer/footer';
import LoginForm from '../../components/molecules/form/LoginForm';
import LogoutForm from '../../components/molecules/form/LogoutForm';
import { AuthContext } from '../../contexts/AuthProvider';
import './user.css';

function UserPage() {
  const { auth } = useContext(AuthContext);

  const loggedIn = !!auth.loginAt;

  return (
    <Container>
      <AppLogo />
      <div className="page-container">
        <div className="user-container">
          <h1>Mitarbeiterbereich</h1>
          {loggedIn ? <LogoutForm /> : <LoginForm />}
        </div>
      </div>
      <Footer />
    </Container>
  );
}

export default UserPage;
