import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie-player';

function SuccessAnimation(props) {
  const { animation } = props;
  return (
    <Lottie
      loop
      animationData={animation}
      play
      style={{ height: '85%', width: '100%' }}
    />
  );
}

SuccessAnimation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  animation: PropTypes.any.isRequired,
};

export default SuccessAnimation;
