import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthProvider, { AuthContext } from './contexts/AuthProvider';
import CheckoutPage from './pages/checkout/checkout';
import HomePage from './pages/home/home';
import PaymentSummary from './pages/payment-summary/payment-summary';
import SummaryPage from './pages/summary/summary';
import UserPage from './pages/user/user';

function App() {
  // check "autologout"
  const { setAuth } = useContext(AuthContext);
  const loginAtFromStorage = localStorage.getItem('loginAt');
  const jetztVorDreissigMinuten = (new Date().getTime() - (1000 * 60 * 30));
  if (loginAtFromStorage && loginAtFromStorage < jetztVorDreissigMinuten) {
    // Wenn login gesetzt war und älter als 30 Minuten ist, dann token removen
    setAuth({
      token: null,
      loginAt: null,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('loginAt');
  }
  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route path="/:id" element={<HomePage />} />
            </Route>
            <Route exact path="/login" element={<UserPage />} />
            <Route exact path="/summary" element={<SummaryPage />} />
            <Route exact path="/checkout" element={<CheckoutPage />} />
            <Route exact path="/payment/success" element={<PaymentSummary view="success" />} />
            <Route exact path="/payment/abort" element={<PaymentSummary view="abort" />} />
            <Route exact path="/payment/failed" element={<PaymentSummary view="failed" />} />
            <Route exact path="/payment/pending" element={<PaymentSummary view="pending" />} />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
