import PropTypes from 'prop-types';
import React from 'react';
import './Container.css';

function Container(props) {
  const { children } = props;
  return <div className="container">{children}</div>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
