import PropTypes from 'prop-types';
import React from 'react';
import './Input.css';

function MyInput(props) {
  const {
    type, name, value, handleInputChange, placeholder, maxLength, isValid, isDisabled, autocomplete,
  } = props;

  return (
    <input
      type={type}
      name={name}
      value={value}
      onChange={isDisabled ? null : handleInputChange}
      className={`Input ${!isValid ? 'Input-Error' : ''} ${isDisabled ? 'Input-Disabled' : ''}`}
      id={`input-${name}`}
      placeholder={placeholder}
      maxLength={maxLength}
      required
      disabled={!!isDisabled}
      autoComplete={autocomplete}
    />
  );
}

MyInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  isValid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autocomplete: PropTypes.string,
};

MyInput.defaultProps = {
  placeholder: null,
  maxLength: null,
  isValid: true,
  isDisabled: false,
  autocomplete: null,
};
export default MyInput;
