import axios from 'axios';

const request = axios.create();

// Get access token
const getAuthToken = () => request.get('api/v1/token');

request.interceptors.request.use(
  (config) => {
    const nconfig = config;
    nconfig.baseURL = process.env.REACT_APP_BOOKING_API_URL;
    if (['api/v1/init-payment', 'api/v1/token', 'api/v1/login'].includes(config.url)) {
      nconfig.baseURL = process.env.REACT_APP_BACKEND_API_URL;
    }
    if (localStorage.getItem('token')) {
      nconfig.headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
      };
    }
    return nconfig;
  },
  (error) => Promise.reject(error),
);

request.interceptors.response.use(
  (response) => {
    const { data } = response;
    return data;
  },
  async (error) => {
    const config = error.config || {};
    if (error.response && error.response.status === 401 && config.url !== 'api/v1/login') {
      const res = await getAuthToken();
      const token = res.data?.access_token;
      localStorage.setItem('token', token);
      if (config?.data) {
        config.data = JSON.parse(config.data);
      }
      return request(config);
    }
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    if (error.request) {
      return Promise.reject(error.request);
    }
    return Promise.reject(error);
  },
);
export default request;
export { getAuthToken };
