import React from 'react';
import AppLogo from '../../components/atoms/applogo/AppLogo';
import Container from '../../components/atoms/container/Container';
import Footer from '../../components/atoms/footer/footer';
import SummaryForm from '../../components/molecules/form/SummaryForm';
import './summary.css';

function SummaryPage() {
  return (
    <Container>
      <AppLogo />
      <SummaryForm />
      <Footer />
    </Container>
  );
}

export default SummaryPage;
