import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const AuthContext = createContext({
  auth: {
    token: localStorage.getItem('token'),
    loginAt: localStorage.getItem('loginAt'),
  },
  loader: false,
  setAuth: () => {
  },
  setLoader: () => {
  },
});

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    loginAt: localStorage.getItem('loginAt'),
  });
  const [loader, setLoader] = useState(false);

  return (
    // TODO useMemo
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{
      auth, setAuth, loader, setLoader,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthProvider;
