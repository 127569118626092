import arrowIcon from '../assets/images/arrowIcon.svg';
import calendarIcon from '../assets/images/calendar.svg';
import camera from '../assets/images/camera.png';
import lock from '../assets/images/lock.png';
import logo from '../assets/images/logo_wdr.png';
import qrIcon from '../assets/images/qrIcon.svg';
import user from '../assets/images/user.png';

const Images = {
  qrIcon,
  lock,
  arrowIcon,
  camera,
  logo,
  calendarIcon,
  user,
};

export default Images;
