import PropTypes from 'prop-types';
import React from 'react';
import './QRZoomModal.css';

function QRZoomModal(props) {
  const { onModalClose, children } = props;
  return (
    <div className="modal" id="modal">
      <div className="popup">
        <div className="Header">
          <h2>Scan QR-Code</h2>
          <button type="button" className="close" onClick={onModalClose}>
            &times;
          </button>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
}

QRZoomModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default QRZoomModal;
