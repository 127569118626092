import PropTypes from 'prop-types';
import React from 'react';
import { FadeLoader } from 'react-spinners';
import './Loader.css';

function Loader({ showLoading }) {
  if (!showLoading) {
    return null;
  }
  return (
    <div className="loader">
      <div className="overlay-box">
        <FadeLoader color="black" loading={showLoading} />
      </div>
    </div>
  );
}

Loader.propTypes = {
  showLoading: PropTypes.bool,
};

Loader.defaultProps = {
  showLoading: false,
};

export default Loader;
