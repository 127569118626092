import request from '../util/request';

// Get booking information
const getBookingInfo = (id) => request.get(`order/ticket/${id}`);

// Get Price information
const rebookOrder = (endTime, payload) => request.post(`order/renting/rebook/${endTime}`, payload);

// Get order price information
const getOrderPriceInfo = (payload) => request.post('order/price', payload);

// Get payment link developer mode
const getPaymentLink = (payload) => request.post('api/v1/init-payment', payload);

const userLogin = (payload) => request.post('api/v1/login', payload);

export {
  getBookingInfo, rebookOrder, getOrderPriceInfo, getPaymentLink, userLogin,
};
