import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="https://www.inselparkplatz.de/geschaeftsbedingungen/">AGB</a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="https://www.inselparkplatz.de/impressum/">Impressum</a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="https://www.inselparkplatz.de/datenschutz/">Datenschutz</a>
    </footer>
  );
}

export default Footer;
