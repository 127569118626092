import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import { getPaymentLink, rebookOrder } from '../../../services';
import { formatCurrency, formatDate } from '../../../util/utils';
import Button from '../../atoms/button/Button';
import InputText from '../../atoms/input/Input';
import TicketDetails from '../ticket-details/TicketDetails';
import './Form.css';

function CheckoutForm() {
  const { state } = useLocation();

  let bookingInfo = null;
  let ticketNumber = null;
  let checkoutAmount = null;
  let checkoutDate = null;

  if (state != null) {
    bookingInfo = state.bookingInfo;
    ticketNumber = state.ticketNumber;
    checkoutAmount = state.checkoutAmount;
    checkoutDate = state.checkoutDate;
  }

  const navigate = useNavigate();
  const { loader, setLoader } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [tnc, setTnc] = useState(false);
  const [updatedBookingInfo, setUpdatedBookingInfo] = useState(bookingInfo);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const onNext = async () => {
    setLoader(true);
    setError(false);
    setEmailError(false);
    try {
      if (email) {
        updatedBookingInfo.kunde.email = email;
        updatedBookingInfo.kunde.versandart = 3;
        updatedBookingInfo.sperre_kz = true; // setze Sperre fuer Zahlungsverarbeitung
        localStorage.setItem('email', email);
        const transformedDateWithFakeISOTime = new Date(checkoutDate - new Date()
          .getTimezoneOffset() * 60 * 1000)
          .toISOString();
        const response = await rebookOrder(transformedDateWithFakeISOTime, updatedBookingInfo);
        setUpdatedBookingInfo(response);

        const paymentLinkResponse = await getPaymentLink(
          { referenceId: bookingInfo.id, amount: checkoutAmount },
        );
        if (paymentLinkResponse?.data?.link) {
          window.open(paymentLinkResponse.data.link, '_self');
        } else {
          setError(true);
        }
      } else {
        setEmailError(true);
      }
    } catch (e) {
      setError(true);
      setLoader(false);
    }
  };

  const onBack = () => {
    navigate('/summary', {
      state: {
        bookingInfo: updatedBookingInfo,
        ticketNumber,
        checkoutDate,
        checkoutAmount,
      },
    });
  };

  if (state == null) {
    return (
      <div className="page-container">
        <div className="checkout-form-container">
          <h1>Bitte scannen Sie erst ein Ticket.</h1>
          <Button label="zurück" handleClick={() => navigate('/')} />
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="checkout-form-container">
        <TicketDetails ticketNumber={ticketNumber} bookingInfo={bookingInfo} />
        <div className="ticket-details-main">
          <h1>
            Ausfahrt bis
            <br />
            {formatDate(checkoutDate)}
            {' '}
            Uhr
          </h1>
          <div className="total-price">
            <span>zu Zahlen:</span>
            <span>
              {` ${formatCurrency(checkoutAmount)}`}
            </span>
          </div>
          <p>
            Um Sie über den Status der Zahlung informieren zu können
            bitte wir Sie eine E-Mail-Adresse anzugeben.
          </p>
          <p>
            Im nächsten Schritt werden Sie zu unserem Zahlungsdienstleister
            weitergeleitet.
          </p>
          <p>
            <strong>Wir wünschen Ihnen eine gute Fahrt!</strong>
          </p>
        </div>
        <InputText
          type="email"
          name="email"
          value={email}
          handleInputChange={(e) => setEmail(e.target.value)}
          placeholder="max.mustermann@email.de"
          className="email-input"
        />
        <div className="tnc-row">
          <input
            id="checkbox"
            className="ios8-switch ios8-switch-lg"
            type="checkbox"
            checked={tnc}
            onChange={() => setTnc(!tnc)}
          />
          <label htmlFor="checkbox">
            Hiermit bestätigt ich das ich die
            {' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="https://www.inselparkplatz.de/geschaeftsbedingungen/">AGB</a>
            {' '}
            gelesen habe
            und akzeptiere diese.
          </label>
        </div>
        {error && <div className="error">Es ist ein Fehler aufgetreten.</div>}
        {emailError && <div className="error">Bitte geben Sie eine E-Mail-Adresse an.</div>}
        <div className="actions">
          <Button
            label="abbrechen"
            handleClick={onBack}
            styleName="white-btn"
          />
          <Button label="bezahlen" handleClick={onNext} disabled={!tnc || !email || loader} />
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
