import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider';
import { userLogin } from '../../../services';
import Button from '../../atoms/button/Button';
import Input from '../../atoms/input/Input';

function LoginForm() {
  const { setLoader, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const handleChangeUsername = (e) => setUsername(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleLoginError = () => {
    setErrorMessage('Beim Login ist ein Fehler aufgetreten.');
    setLoader(false);
  };

  const onLogin = async () => {
    setLoader(true);
    setErrorMessage(null);
    try {
      const response = await userLogin({ username, password });
      if (response?.data?.access_token) {
        const token = response.data.access_token;
        const loginAt = new Date().getTime();
        setAuth({ token, loginAt });
        localStorage.setItem('token', token);
        localStorage.setItem('loginAt', loginAt.toString());
        setLoader(false);
        navigate('/');
      } else {
        handleLoginError(new Error('Kein Accesstoken erhalten!'));
      }
    } catch (e) {
      handleLoginError(e);
    }
  };
  return (
    <>
      <Input
        type="email"
        name="username"
        value={username}
        placeholder="E-Mail"
        handleInputChange={handleChangeUsername}
        autocomplete="username"
      />
      <Input
        type="password"
        name="password"
        value={password}
        placeholder="Passwort"
        handleInputChange={handleChangePassword}
        autocomplete="current-password"
      />
      <Button handleClick={onLogin} label="login" disabled={!username || !password} />
      {!!errorMessage && <div className="error">{errorMessage}</div>}
    </>
  );
}

export default LoginForm;
