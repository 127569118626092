const formatCurrency = (amount) => {
  let namount = amount;
  if (amount) {
    namount /= 100;
  }
  const formatCurrencyInner = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
  return formatCurrencyInner.format(namount);
};

const formatDate = (date) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
    hour12: false,
  };
  return new Intl.DateTimeFormat('de-DE', options).format(date);
};

export { formatCurrency, formatDate };
